import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Accordion,
  Card,
  Carousel,
  Col,
  Container,
  Dropdown,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import about_img from "../../images/abt.jpg";
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  faLocationArrow,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../images/SDR logo.png";
import logoSmall from "../../images/SDR-Logo-small.png";
import bannerImg from "../../images/banner.jpeg";
import bannerImg2 from "../../images/banner-2.jpg";
import bannerImg3 from "../../images/banner-3.jpeg";
import {
  getAllItems,
  getComboOffers,
  getItemBySubCategory,
  getItemOffers,
  getMenuCategoryAndSubCat,
  getMenuItemsBySubCategory,
  getRestaurantCategoryList,
  getSubCategoryByCategory,
} from "../../api/menuDetails";
import ItemCard from "./ItemCard";
import ComboItemCard from "./ComboItemCard";
import OfferItemCard from "./OfferItemCard";
import ItemModal from "./ItemModal";

function App() {
  const [activeSection, setActiveSection] = useState("");

  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [items, setItems] = useState();
  const [menuId, setMenuId] = useState();

  const selectedRestaurant = 64;
  const getRestaurantCategory = async () => {
    const response = await getMenuCategoryAndSubCat(selectedRestaurant);
    if (response.data[0]?.categories) {
      setMenuId(response.data[0]?.menuId);
      setCategory(JSON.parse(response.data[0]?.categories));
      setActiveTab(JSON.parse(response.data[0]?.categories)[0].categoryId);
    }
    return response?.data;
  };

  // getRestaurantsubCategory
  const getRestaurantsubCategory = async (id) => {
    const sub = category?.find(
      (el) => parseInt(el.categoryId, 10) === parseInt(id, 10)
    );

    if (sub) {
      sub?.subCategories?.sort(
        (a, b) => a.subCategoryorderNo - b.subCategoryorderNo
      );
      setSubCategory(sub?.subCategories);
      setActiveSubCategoryTab(sub?.subCategories[0]?.subCategoryId);

      getItems(sub?.subCategories[0]?.subCategoryId);
    }
    return sub;
  };

  const getItems = async (subId) => {
    if (subId) {
      const response = await getMenuItemsBySubCategory({
        restaurantId: selectedRestaurant,
        subCategoryId: subId,
        menuId: menuId,
      });
      setItems(response?.data);
      return response?.data;
    }
  };

  const [itemOfferList, setItemOfferLIst] = useState();
  const [comboList, setComboList] = useState();
  const getItemOffersList = async () => {
    const response = await getItemOffers(selectedRestaurant);
    setItemOfferLIst(response?.data);

    return response?.data;
  };

  const getComboOffersList = async () => {
    const response = await getComboOffers(selectedRestaurant);
    // setItems();
    setComboList(response?.data);
    return response?.data;
  };

  useEffect(() => {
    getRestaurantCategory();
    getItemOffersList();
    getComboOffersList();
  }, []);

  const [activeSectionCat, setActiveSectionCat] = useState("");

  const handleScroll = () => {
    const sections = document.querySelectorAll("section");
    const scrollPosition = window.pageYOffset;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 80;
      const sectionHeight = section.clientHeight;
      if (
        scrollPosition >= sectionTop - 50 &&
        scrollPosition < sectionTop + sectionHeight
      ) {
        setActiveSection(section.getAttribute("id"));
      }
    });

    let divs = document.querySelectorAll(".categ-div");

    divs.forEach((div) => {
      const rect = div.getBoundingClientRect();
      if (rect.top >= 0 && rect.top <= 300) {
        setActiveSectionCat(div.id);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          return true;
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
  }, []);

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const counterElement = document.getElementById("counter");
      const oTop =
        counterElement?.getBoundingClientRect().top - window.innerHeight;

      if (!hasScrolled && window.scrollY > oTop) {
        document.querySelectorAll(".counter-value").forEach((element) => {
          const countTo = parseInt(element.getAttribute("data-count"), 10);
          const startCount = parseInt(element.innerText, 10);

          let currentCount = startCount;

          const animateCount = () => {
            const increment = countTo / 100; // Adjust for the smoothness of the count
            if (currentCount < countTo) {
              currentCount += increment;
              element.innerText = Math.floor(currentCount);
              requestAnimationFrame(animateCount);
            } else {
              element.innerText = countTo;
            }
          };

          animateCount();
        });
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  const aboutRef = useRef(null);
  const menuRef = useRef(null);
  const servicesRef = useRef(null);
  const contactRef = useRef(null);

  const handleSmoothScroll = (e, sectionRef) => {
    e.preventDefault(); // Prevent the default anchor behavior
    if (sectionRef.current) {
      const targetPosition =
        sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: targetPosition - 100, // Scroll 100px above the div
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState("arabic"); // Default active tab

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen is mobile (width less than 768px)
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check screen size on initial load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // // Handling tab change for both Tabs and Dropdown
  // const handleTabSelect = (tabKey) => {
  //   setActiveTab(tabKey);
  // };

  // Handling tab change for both Tabs and Dropdown
  const handleTabSelect = (tabKey) => {
    setActiveTab(tabKey);
  };

  const [activeSubCategoryTab, setActiveSubCategoryTab] = useState("");

  const handleSubCategoryTabSelect = (tabKey) => {
    setActiveSubCategoryTab(tabKey);
  };

  useEffect(() => {
    getRestaurantsubCategory(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setItems([]);
    getItems(activeSubCategoryTab);
  }, [activeSubCategoryTab]);

  const title = (title, subTitle, className) => {
    return (
      <div className={className}>
        {title}
        <span>{subTitle}</span>
      </div>
    );
  };

  const [open, setOpen] = useState(false);
  const [itmData, setItmData] = useState();
  const [itemType, setItemType] = useState();

  const arabicTabContent = (id) => {
    //
    return (
      subCategory && (
        <Tabs
          defaultActiveKey={subCategory[0]?.subCategoryId}
          onSelect={handleSubCategoryTabSelect}
          activeKey={activeSubCategoryTab}
          id="uncontrolled-tab-example"
          className="justify-content-center mb-3"
        >
          {subCategory?.map((el) => (
            <Tab
              eventKey={el.subCategoryId}
              title={title(
                el.subCategoryName,
                el.subCategoryNameOther,
                "d-flex flex-column"
              )}
              style={{ minHeight: "300px" }}
            >
              {/* Item name images */}
              <div
                className="row"
                style={{ maxHeight: "900px", overflow: "hidden auto" }}
              >
                {items?.map((data) => (
                  <div className="col-md-4 mb-4">
                    <Card
                      style={{ height: "100%" }}
                      onClick={() => {
                        setOpen(true);
                        setItmData(data);
                        setItemType("item");
                      }}
                    >
                      <img
                        src={process.env.REACT_APP_baseUrl + data?.itemImage}
                        alt=""
                      />
                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <h5 className="title">{data?.itemName}</h5>
                          <h5 className="title">{data?.itemNameOther}</h5>
                        </div>
                        <div className="price">{data?.itemPrice}</div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
      )
    );
  };

  const comboOfferTab = (id) => {
    return (
      comboList?.length && (
        <div className="row pt-3">
          {comboList?.map((data) => (
            <div className="col-md-4 mb-4">
              <div
                className="item-card"
                onClick={() => {
                  setOpen(true);
                  setItmData(data);
                  setItemType("combo");
                }}
              >
                <img
                  src={process.env.REACT_APP_baseUrl + data?.comboOfferImgUrl}
                  alt=""
                />
                <h5 className="title text-center">{data?.comboOfferName}</h5>
                <div className="d-flex justify-content-between p-3">
                  <div>
                    {JSON.parse(data.item).map((el, index) => (
                      <>
                        <h5 className="title sub-title">{el?.itemName}</h5>
                        <div className="text-center">
                          {index < JSON.parse(data.item)?.length - 1 ? "+" : ""}
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="align-content-center">
                    <del style={{ color: "#656565" }}>{data?.comboPrice}</del>
                    <div className="price">{data?.comboOfferPrice}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  const itemOfferTab = (id) => {
    return (
      itemOfferList?.length && (
        <div className="row pt-3">
          {itemOfferList?.map((data) => (
            <div className="col-md-4 mb-4">
              <div
                className="item-card"
                onClick={() => {
                  setOpen(true);
                  setItmData(data);
                  setItemType("itemOffer");
                }}
              >
                <img
                  src={process.env.REACT_APP_baseUrl + data?.itemOfferImgUrl}
                  alt=""
                />
                <div className="d-flex justify-content-between p-3">
                  <div>
                    <h5 className="title">{data?.itemOfferName}</h5>
                    <h5 className="title sub-title">
                      ({data?.itemName} - {data?.nameOther})
                    </h5>
                  </div>
                  {/* <div className="dotted-div"></div> */}
                  <div>
                    <del style={{ color: "#656565" }}>{data?.price}</del>
                    <div className="price">{data?.offerPrice}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  const catTitle = () => {
    if (activeTab === "itemOffers") {
      return (
        <div className="d-flex justify-content-between drop-cat-title ">
          Item Offers
        </div>
      );
    } else if (activeTab === "comboOffer") {
      return (
        <div className="d-flex justify-content-between drop-cat-title ">
          Combo Offers
        </div>
      );
    } else {
      const name = category?.find(
        (el) => parseInt(el.categoryId, 10) === parseInt(activeTab, 10)
      );

      return (
        <div className="d-flex justify-content-between drop-cat-title ">
          {name.categoryName}
          <span>{name.categoryNameOther}</span>
        </div>
      );
    }
  };

  const transformData = (data) => {
    const result = {};

    data?.forEach((item) => {
      const { categoryName, subCategoryName, orderNo, ...itemData } = item;

      if (!result[categoryName]) {
        result[categoryName] = { orderNo, subcategories: {} };
      }
      if (!result[categoryName].subcategories[subCategoryName]) {
        result[categoryName].subcategories[subCategoryName] = [];
      }

      result[categoryName].subcategories[subCategoryName].push(itemData);
    });

    return Object.entries(result)
      .sort((a, b) => a[1].orderNo - b[1].orderNo)
      .reduce((acc, [key, value]) => {
        acc[key] = value.subcategories;
        return acc;
      }, {});
  };

  const [allItems, setAllItems] = useState();
  const getAllItem = async () => {
    const response = await getAllItems(selectedRestaurant);
    const categorizedData = transformData(response?.data[0]?.item);

    if (categorizedData) {
      setAllItems(categorizedData);
    }
    return response?.data;
  };

  useEffect(() => {
    getAllItem();
  }, []);

  return (
    <div className="App">
      <header>
        <Navbar
          expand="lg"
          fixed={!isMobile ? `top ${isShrunk ? "reduced-nav" : ""}` : ""}
        >
          <Container>
            <Navbar.Brand href="#home">
              {isShrunk ? (
                <img src={logoSmall} alt="" />
              ) : (
                <img src={logo} alt="" />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="main-menu">
                <Nav.Link
                  className={`${activeSection === "home" ? "active-menu" : ""}`}
                  href="#home"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="#!"
                  className={`${
                    activeSection === "about" ? "active-menu" : ""
                  }`}
                  onClick={(e) => handleSmoothScroll(e, aboutRef)}
                >
                  About Us
                </Nav.Link>
                <Nav.Link
                  className={`${activeSection === "menu" ? "active-menu" : ""}`}
                  href="#!"
                  onClick={(e) => handleSmoothScroll(e, menuRef)}
                >
                  Menu
                </Nav.Link>
                <Nav.Link
                  href="#!"
                  className={`${
                    activeSection === "services" ? "active-menu" : ""
                  }`}
                  onClick={(e) => handleSmoothScroll(e, servicesRef)}
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  className={`${
                    activeSection === "contact-us" ? "active-menu" : ""
                  }`}
                  href="#!"
                  onClick={(e) => handleSmoothScroll(e, contactRef)}
                >
                  Contact Us
                </Nav.Link>
                <Nav.Link href="tel:23294490" className={`phone-no-item`}>
                  <FontAwesomeIcon icon={faPhone} /> 23294490
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <section id="home" className="main-banner">
        <Carousel>
          <Carousel.Item>
            <img src={bannerImg} alt="" />
            <Carousel.Caption>
              <Container>
                <div className="banner-overlay">
                  <h3 className="bannerHead">
                    Good vibes, great food,
                    <br /> amazing memories
                  </h3>
                  <p className="bannerSubHead">
                    Where SOPHISTICATION meets flavor in perfect harmony.
                  </p>
                </div>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={bannerImg2} alt="" />
            <Carousel.Caption>
              <Container>
                <div className="banner-overlay">
                  <h3 className="bannerHead">
                    Food that tells a story <br /> with every course
                  </h3>
                  <p className="bannerSubHead">
                    Experience the ART of fine dining on your plate.
                  </p>
                </div>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={bannerImg3} alt="" />
            <Carousel.Caption>
              <Container>
                <div className="banner-overlay">
                  <h3 className="bannerHead">
                    Elevate your dining experience, <br /> one dish at a time{" "}
                  </h3>
                  <p className="bannerSubHead">
                    Taste the EXTRAORDINARY in everyday bites.
                  </p>
                </div>
              </Container>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      <section id="about" ref={aboutRef} className="py-100">
        <div className="container">
          <h2 className="text-center mb-5">Our Story</h2>
          <Row className="about-content">
            <Col lg={6}>
              <img src={about_img} alt="" />
            </Col>
            <Col lg={6}>
              <p>
                Silver Diamond Restaurant is a name synonymous with culinary
                excellence. Established in the year 2004, from humble
                beginnings, we have catapulted ourself to a multicuisine eatery
                over the past 20 years. Serving Indian, Arabic, Chinese and
                various other delicacies to the melting pot populace of Salalah,
                we have carved out a niche among the beaneries of this wonderful
                city.
              </p>
              <p>
                Spread over a sizable area, there is more than ample space to
                accommodate 100 people at a stretch. There are cubicles of
                various dimensions to suit families of different sizes and of
                course the spacious common dining hall and also a mini party
                area to complement the amenities.
              </p>
              <p>
                We are situated in the heart of Salalah in a very easily
                accessible location (near New Salalah Government Health Centre)
                with sufficient car parking. You are most welcome to pay us a
                visit and allow your taste buds to embark on a delightful
                gastronomic journey.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      <section id="menu" ref={menuRef} className="py-100">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Our Menu</h2>
            {/* <div className="col-md-3"> */}
            {/* <h3 className="text-center mb-5">Our Categories</h3> */}
            <div className="tab-container mobile-display">
              {isMobile ? (
                <>
                  <div className="mob-category-list navbar sticky-top">
                    <div className="w-100">
                      <ul>
                        {category?.map((item) => (
                          <li>
                            <a
                              href={`#${item.categoryName}`}
                              className={
                                activeSectionCat === item.categoryName
                                  ? "active"
                                  : ""
                              }
                            >
                              <div>{item.categoryName}</div>

                              <span>{item?.categoryNameOther}</span>
                            </a>
                          </li>
                        ))}

                        {itemOfferList?.length ? (
                          <li>
                            <a
                              href={`#itemOffers`}
                              className={
                                activeSectionCat === "itemOffers"
                                  ? "active"
                                  : ""
                              }
                            >
                              <div>Item Offer</div>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                        {comboList?.length ? (
                          <li>
                            <a
                              href={`#comboOffer`}
                              className={
                                activeSectionCat === "comboOffer"
                                  ? "active"
                                  : ""
                              }
                            >
                              <div>Combo Offer</div>
                            </a>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div>
                      {allItems &&
                        Object.keys(allItems)?.map((el) => (
                          <div
                            id={el}
                            style={{ marginBottom: "40px" }}
                            className="categ-div"
                          >
                            <h6>{el}</h6>
                            {allItems[el] && (
                              <Accordion
                                alwaysOpen
                                defaultActiveKey={
                                  allItems[el]
                                    ? Object.keys(allItems[el]).flatMap(
                                        (sub) => sub
                                      )
                                    : []
                                }
                              >
                                {Object.keys(allItems[el])?.map((sub) => (
                                  <div
                                    id={sub}
                                    style={{ marginBottom: "20px" }}
                                  >
                                    <Accordion.Item eventKey={sub}>
                                      <Accordion.Header>{sub}</Accordion.Header>
                                      <Accordion.Body>
                                        {allItems[el][sub]?.map((itm) => (
                                          <ItemCard item={itm} />
                                        ))}
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  </div>
                                ))}
                              </Accordion>
                            )}
                          </div>
                        ))}
                      {itemOfferList?.length ? (
                        <div id="itemOffers" className="categ-div">
                          <h6>Item Offers</h6>
                          {itemOfferList?.map((itm) => (
                            <OfferItemCard item={itm} />
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {comboList?.length ? (
                        <div id="comboOffer" className="categ-div">
                          <h6>Combo Offers</h6>
                          {comboList?.map((itm) => (
                            <ComboItemCard item={itm} />
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                category && (
                  <Tabs
                    defaultActiveKey={category[0]?.categoryId}
                    onSelect={handleTabSelect}
                    activeKey={activeTab}
                    id="menu-page-catogories"
                    className="mb-2 flex-column nav-category-menu"
                  >
                    <Tab eventKey={"head"} title={"Categories"} disabled>
                      Categories
                    </Tab>
                    {category?.map((el) => (
                      <Tab
                        eventKey={el.categoryId}
                        title={title(
                          el.categoryName,
                          el.categoryNameOther,
                          "d-flex justify-content-between cat-item-title"
                        )}
                      >
                        {/* Item subcategory name */}
                        {arabicTabContent(el.categoryId)}
                      </Tab>
                    ))}
                    {comboList?.length || itemOfferList?.length ? (
                      <Tab eventKey={"head"} title={"Offers"} disabled>
                        Offers
                      </Tab>
                    ) : (
                      ""
                    )}
                    {itemOfferList?.length ? (
                      <Tab
                        eventKey="itemOffers"
                        title={title(
                          "Item Offer",
                          "",
                          "d-flex justify-content-between cat-item-title"
                        )}
                      >
                        {/* Item subcategory name */}
                        {itemOfferTab()}
                      </Tab>
                    ) : (
                      ""
                    )}
                    {comboList?.length ? (
                      <Tab
                        eventKey={"comboOffer"}
                        title={title(
                          "Combo Offer",
                          "",
                          "d-flex justify-content-between cat-item-title"
                        )}
                      >
                        {/* Item subcategory name */}
                        {comboOfferTab()}
                      </Tab>
                    ) : (
                      ""
                    )}
                  </Tabs>
                )
              )}
            </div>
          </div>
        </div>
        <ItemModal
          item={itmData}
          type={itemType}
          show={open}
          handleClose={() => setOpen(false)}
        />
      </section>

      <section id="services" ref={servicesRef} className="text-center py-100">
        <div className="container">
          <h2 className="mb-5">Our Services</h2>
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faUtensils} className="fontIcon" />
                <h5 className="serviceHead">Dining</h5>
                <p>
                  Our dining service offers a refined experience with a diverse
                  menu designed to suit any occasion. Enjoy exceptional cuisine
                  and service in a welcoming atmosphere.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faBagShopping} className="fontIcon" />
                <h5 className="serviceHead">Take Away</h5>
                <p>
                  Our take-away service allows you to enjoy our delicious foods
                  at home. Convenient and flavorful, it's perfect for any time
                  you want a restaurant-quality meal without the dining in
                  experience.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faTruck} className="fontIcon" />
                <h5 className="serviceHead">Home Delivery</h5>
                <p>
                  Our home delivery service brings our delectable dishes
                  straight to your door. With fast and reliable delivery, you
                  can savor our diverse menu without stepping out.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card">
                <FontAwesomeIcon icon={faCakeCandles} className="fontIcon" />
                <h5 className="serviceHead">Accommodate all events</h5>
                <p>
                  We accommodate all events, offering a versatile space and
                  personalized service to meet your needs. our team ensures
                  every detail is tailored to create a memorable experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="counter-section"
        className="text-center py-100 counter-content"
      >
        {/* <img src={counterimg} alt="" /> */}
        {/* <div className="container">
          <div className="counter-bg">
            <img src={counterimg} alt="" />
          </div>
        </div> */}
        <div className="overlay"></div>
        <div id="counter" className="container">
          <div className="row justify-content-around">
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="50">
                  0
                </span>
                +
              </div>
              <h6>Categories</h6>
            </div>
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="100">
                  0
                </span>
                +
              </div>
              <h6>Food Items</h6>
            </div>
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="25">
                  0
                </span>
                k+
              </div>
              <h6>Happy Customers</h6>
            </div>
            <div className="col-sm-6 col-md-2 counter-div">
              <div className="counter-data">
                <span className="counter-value" data-count="15">
                  0
                </span>
                k+
              </div>
              <h6>Deliveries</h6>
            </div>
          </div>
        </div>
      </section>

      <section id="contact-us" ref={contactRef}>
        <div className="contact-data-container">
          <div className="container">
            <div className="contact-data">
              <h4>Contact Us</h4>
              <ul className="content-contact">
                <li>
                  <FontAwesomeIcon
                    icon={faLocationArrow}
                    className="fontAddressIcon"
                  />
                  <p>
                    Silver diamond restaurant Salalah Oman <br />
                    Near New Salalah Health Center
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="fontAddressIcon"
                  />
                  <p>business@silverdiamond.com</p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} className="fontAddressIcon" />{" "}
                  23294490
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="fontAddressIcon footer-whatsapp"
                  />{" "}
                  91412135
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3815.131644816951!2d54.08864317515674!3d17.017211583808166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDAxJzAyLjAiTiA1NMKwMDUnMjguNCJF!5e0!3m2!1sen!2sin!4v1734102375598!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <footer>
        <div className="container foot-content">
          <div className="row row justify-content-between">
            <div className="col-md-3 footer-about-sec">
              <h4>About Us</h4>
              <p>
                Silver Diamond Restaurant is a name synonymous with culinary
                excellence. Established in the year 2004, from humble
                beginnings.
              </p>
            </div>

            <div className="col-md-3 subscribe-section">
              <h4>Services</h4>
              <div className="mt-4">
                <ul className="navbar-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Dining
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      Take Away
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      Home Delivery
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#menu">
                      Accommodate all events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 subscribe-section">
              <h4>Quick Links</h4>
              <div className="mt-4">
                <ul className="navbar-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#services">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#menu">
                      Menu
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact-us">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 insta-section">
              <h4>Stay Connected</h4>
              <div className="row">
                <ul className="navbar-nav-footer social-nav-footer mb-2 mb-lg-0">
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      className="fontAddressIcon"
                    />
                  </li>
                  <li className="nav-item">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      className="fontAddressIcon"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center copyright_section">
          &copy; 2024. All rights reserved
        </div>
      </footer>
    </div>
  );
}

export default App;
