import { useState } from "react";
import ItemModal from "./ItemModal";

const OfferItemCard = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [itmData, setItmData] = useState();

  return (
    <>
      <div
        className="item-card-mob"
        onClick={() => {
          setOpen(true);
          setItmData(item);
        }}
      >
        <h6>{item.itemOfferName}</h6>
        <div className="d-flex mob-item-details">
          <img
            src={process.env.REACT_APP_baseUrl + item?.itemOfferImgUrl}
            alt=""
          />
          <div className="description-det d-flex justify-content-between">
            <span className="ar-name">
              ({item?.itemName} - {item?.nameOther})
            </span>
            <div>
              <del style={{ color: "#656565" }}>{item?.price}</del>
              <div className="price">{item?.offerPrice}</div>
            </div>
          </div>
        </div>
      </div>
      <ItemModal
        item={itmData}
        type={"itemOffer"}
        show={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default OfferItemCard;
