import { useState } from "react";
import ItemModal from "./ItemModal";

const ComboItemCard = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [itmData, setItmData] = useState();

  return (
    <>
      <div
        className="item-card-mob"
        onClick={() => {
          setOpen(true);
          setItmData(item);
        }}
      >
        <h6>{item.comboOfferName}</h6>
        <div className="d-flex mob-item-details">
          <img
            src={process.env.REACT_APP_baseUrl + item?.comboOfferImgUrl}
            alt=""
          />
          <div className="description-det d-flex justify-content-between">
            <span className="ar-name">
              {JSON.parse(item.item).map((el, index) => (
                <>
                  <h5 className="title sub-title">{el?.itemName}</h5>
                  <div className="text-center">
                    {index < JSON.parse(item.item)?.length - 1 ? "+" : ""}
                  </div>
                </>
              ))}
            </span>
            <div>
              <del style={{ color: "#656565" }}>{item?.comboPrice}</del>
              <div className="price">{item?.comboOfferPrice}</div>
            </div>
          </div>
        </div>
      </div>
      <ItemModal
        item={itmData}
        type={"combo"}
        show={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default ComboItemCard;
