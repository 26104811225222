import axios from "./axios";

const getRestaurantCategoryList = (id) => {
  return axios.post(`/categoryWithRestaurantId`, { restaurantId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getSubCategoryByCategory = (id) => {
  return axios.post(`/subCategoryByCategoryId`, { categoryId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getItemBySubCategory = (id) => {
  return axios.post(`/itemBySubCategoryId`, { subCategoryId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getMenuCategoryAndSubCat = (id) => {
  return axios
    .post(`/getMenuCategoryandSubcategoryByRestaurantId?restaurantId=${id}`)
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error.response;
      }
    );
};

const getMenuItemsBySubCategory = (params) => {
  return axios.get(`/getMenuItemsBySubCategory`, { params }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getItemOffers = (id) => {
  return axios.post(`/getItemOffersByRestaurantId`, { restaurantId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getComboOffers = (id) => {
  return axios.post(`/getComboOfferByRestaurantId`, { restaurantId: id }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

const getAllItems = (restaurantId) => {
  return axios.post("/getMenuByRestaurantId", { restaurantId }).then(
    (res) => {
      return res;
    },
    (error) => {
      return error.response;
    }
  );
};

export {
  getRestaurantCategoryList,
  getSubCategoryByCategory,
  getItemBySubCategory,
  getMenuCategoryAndSubCat,
  getMenuItemsBySubCategory,
  getComboOffers,
  getItemOffers,
  getAllItems,
};
